@import url("https://fonts.googleapis.com/css2?family=Neucha&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800;900&display=swap");

body {
	margin: 0;
	height: auto;
	font-family: "Inter", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #303b44;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

li::marker {
	color: var(--color-typo-primary);
}

.Text {
	white-space: pre-wrap;
}

li p {
	margin: 0;
}

.hidden {
	display: none !important;
	visibility: hidden;
}

.blocker {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	content: " ";
}

.container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-y: hidden;
}

.mainContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 40px;
	width: fit-content;
	max-width: 1240px;
	margin-left: auto;
	margin-right: auto;
}

.contentBlock {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	row-gap: 40px;
}

.contentBlock > h2 {
	text-align: center;
}

.modulesList {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 40px;
}

.modulesList > .moduleCard {
	flex-grow: 1;
}

.loginForm {
	min-width: 255px;
	max-width: 285px;
	padding: 40px;
}

.loginFormHeader {
	margin: 0;
}

.loginForm > * {
	padding-bottom: 32px;
}

.eduHeader {
	background-color: #303b44;
	width: 100%;
	height: 48px;
}

.mainContainer {
	flex-grow: 2;
	padding: 40px 20px;
}

.headerContainer {
	margin: 0 auto;
	padding: 0 12px;
	justify-content: space-between;
	display: flex;
	align-items: center;
	height: 100%;
}

.moduleSidebar {
	flex-grow: 1;
	max-width: 700px;
	width: 278px;
	padding: 12px 40px;
}

.sidebarMascot {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 150px;
	overflow: hidden;
	border-radius: 50%;
	background-color: #fff;
	margin-bottom: 16px;
	margin-left: 12px;
}

.sidebarHeader {
	margin-bottom: 24px;
	margin-left: 12px;
}

.oneColumn {
	padding: 40px;
	max-width: 700px;
	overflow: auto;
}

.twoColumns {
	padding-top: 40px;
	max-width: 1280px;
	overflow: auto;
}

.pageTitle,
.pageSubtitle {
	margin: 0;
	margin-bottom: 16px;
}

.contentButtons {
	display: flex;
	justify-content: flex-end;
}

.hint {
	margin-top: 8px;
}

.hint_disabled {
	display: none;
}

.analys_disabled {
	display: none;
}

.help_container {
	margin-bottom: 24px;
}

.link__no__decoration {
	text-decoration: none;
}

.modal__content {
	height: 100vh;
	width: 100vw;
	padding: 16px;
}

.modal__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 16px;
	margin-bottom: 16px;
	border-bottom: 1px solid var(--color-typo-ghost);
}

.modal__image__container {
	min-height: auto;
	height: calc(100% - 90px);
	overflow-y: auto;
}

.modal__image__container img {
	width: 100%;
}

.Checkbox-Input::before {
	z-index: auto !important;
}

.SnackBar {
	position: absolute;
	top: 64px;
	right: 16px;
}

.image__small {
	max-width: 300px;
}

.image__medium {
	max-width: 420px;
}

.image__large {
	max-width: 620px;
}

.lesson__menu {
	padding: 16px 40px;
}

.iFrameContainer {
	position: relative;
}

.iFrameContainer iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.iFrameContainer .ratio {
	display: block;
	width: 100%;
	height: auto;
}

.loaderContainer {
	width: 100%;
}
