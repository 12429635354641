.logoContainer {
	display: flex;
	width: 500px;
	align-items: center;
	text-decoration: none;
}

.logoContainer img {
	margin: 0;
	padding-left: 12px;
	padding-right: 4px;
}

.headerLogo {
	margin: 0;
	padding: 8px;
	color: #fafafa;
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.headerTaskNav {
	width: 540px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 4px;
}

.headerTaskNav span {
	padding: 6px;
}

.headerNavDivider {
	content: "";
	display: block;
	margin-left: 8px;
	margin-right: 8px;
	margin-top: 4px;
	height: 1px;
	flex-grow: 2;
	background: #fff;
}

.headerHelp {
	width: 500px;
	display: flex;
	justify-content: flex-end;
	vertical-align: middle;
	gap: 8px;
}

.headerDivider {
	font-size: 30px;
	line-height: 32px;
	font-weight: 100;
	color: #fff;
}

.helpLinks {
	list-style: none;
	margin: 0;
	padding: 0;
}

.helpLinksItem a {
	color: var(--color-text-primary);
	text-decoration: none;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 8px;
}

.helpLinksText {
	margin: 0;
	padding: 8px;
	text-align: end;
}

.userHelp {
	width: 278px;
	position: absolute;
	top: 48px;
	right: 0;
	margin: 12px;
	padding: 8px;
	box-shadow: var(--shadow-layer);
	z-index: 99;
}

@media (max-width: 900px) {
	.headerLogo {
		display: none;
	}
}
