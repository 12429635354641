.headerNavButton {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 4px;
	max-width: 760px;
}

.navButton {
	display: flex;
	align-items: baseline;
	margin: 0 auto;
	gap: var(--space-s);
}

.navButton .icon {
	margin-top: auto;
	margin-bottom: auto;
}

.navButton .badge {
	display: flex;
	height: 24px;
	margin-top: auto;
	margin-bottom: auto;
}

.headerNavButton.active {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	background-color: #fff;
}

@media (max-width: 1280px) {
	.headerNavButton {
		max-width: 640px;
	}

	.navButton .badge {
		display: none;
	}
}

@media (max-width: 900px) {
	.headerNavButton {
		width: 160px;
	}
	.navButtonTitle {
		display: none;
	}
}
